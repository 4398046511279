import { get } from "lodash";
import { TRANSACCIONES, LOGIN, METRICS, GENERAL, BENEFICIOS } from "./enpoints";

// Se importan las estructuras REST de funciones Asyncronas
const { getWithoutParams, post } = require("../request/index");

// Funciones de transacciones
const getTrxs = () => post(TRANSACCIONES, {});
const getTrxsFilters = (filters) => post(TRANSACCIONES, filters);

// Funciones de inicio de sesión temporal
const signInLogin = (userLogin) => post(LOGIN, userLogin);

// Obtener datos
const getAllMetrics = () => getWithoutParams(METRICS);
const getGeneralResume = () => getWithoutParams(GENERAL);

// Beneficios subir archivo
const loadBenefits = (data) => post(BENEFICIOS, data);

export {
  getTrxs,
  getTrxsFilters,
  signInLogin,
  getAllMetrics,
  getGeneralResume,
  loadBenefits,
};
