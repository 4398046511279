import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TableGeneric } from "../shared/TableGeneric";

const unOperativas = [
  { id: 1, optionVal: "DISTRIBUIDORA" },
  { id: 2, optionVal: "CENTRO" },
  { id: 3, optionVal: "NORTE" },
  { id: 4, optionVal: "OESTE" },
  { id: 5, optionVal: "SUR" },
  { id: 6, optionVal: "YPF"},
  { id: 7, optionVal: "SHELL"},
  { id: 8, optionVal: "AXION"},
  { id: 8, optionVal: "OTROS"}
];

export const DetailResume = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filters, setFilters] = useState([]);

  const model = [
    { id: "ruta", label: "UUOO" },
    {
      id: "beneficios_otorgados",
      label: "Beneficios Otorgados (Tapas)",
      align: "right",
    },
    { id: "tapas_recibidas", label: "Tapas Entregadas", align: "right" },
    { id: "saldo", label: "Pendientes de Entrega", align: "right" },
    { id: "saldo_percent", label: "Avance Entregas", align: "right" },
    {
      id: "beneficios_otorgados_botellas",
      label: "Beneficios Otorgados (Botellas)",
      align: "right",
    },
  ];

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      if (filters.length > 0) {
        console.log("Cambiar data: ", filters);

        console.log(data);
        let aux = data.filter((el) =>
          filters.some((x) => x === el.unidad_operativa)
        );
        setFilteredData(aux);
      } else {
        setFilteredData(data);
      }
    }
    return () => (excecute = false);
  }, [filters]);

  return (
    <Grid container>
      <Grid xs={12} md={3}>
        <Filters updateFilters={setFilters} filters={filters} />
      </Grid>
      <Grid xs={12} md={9}>
        <TableGeneric model={model} data={filteredData} total={true} />
      </Grid>
    </Grid>
  );
};

const Filters = ({ updateFilters, filters }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom component="div">
        Unidad operativa
      </Typography>

      <UnidadOperativaFiltros updateFilters={updateFilters} filters={filters} />
    </Box>
  );
};

const UnidadOperativaFiltros = ({ updateFilters, filters }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {unOperativas.map((el) => (
        <Box key={el.id} style={{ margin: 5 }}>
          <UOFiltro el={el} updateFilters={updateFilters} filters={filters} />
        </Box>
      ))}
    </Box>
  );
};

const UOFiltro = ({ el, updateFilters, filters }) => {
  const [estado, setEstado] = useState(false);

  const addRemoveFilter = () => {
    let auxFilters = filters;

    updateFilters(
      estado
        ? filters.filter((elem) => elem !== el.optionVal)
        : [...filters, el.optionVal]
    );
    setEstado(!estado);
  };

  return (
    <Chip
      sx={{
        borderRadius: 2,
      }}
      onClick={addRemoveFilter}
      label={el.optionVal}
      color="error"
      variant={estado ? "filled" : "outlined"}
    />
  );
};
