import React, { useState } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

import { SignIn, SignUp } from "../views/session/index";
import { Home, Dashboard, BottleCaps, General } from "../views/home/index";
import { Benefits } from "../views/home/Benefits";

export const RootNavigation = (props) => {
  const { authenticated, roleAdmin } = useSelector((state) => state.session);

  let privateRoutesArray = roleAdmin
    ? privateRoutes.concat(adminRoutes)
    : privateRoutes;

  /**
   * Este hook permite crear rutas como un array
   */
  const notAuthRoutes = useRoutes(publicRoutes);
  const authenticatedRoutes = useRoutes(privateRoutesArray);

  return <>{authenticated ? authenticatedRoutes : notAuthRoutes}</>;
};

/**
 * Rutas publicas con token false
 * las rutas children heredan las propiedades del padre y se renderizan
 * dentro de su estructura.
 * Para saltear la estructura es necesario crear un nuevo objeto con sus propios children
 * no anidados.
 * */
export const publicRoutes = [
  { path: "/", element: <SignIn /> },
  { path: "*", element: <SignIn /> },
];

/**
 * cada modulo nuevo en el backoffice debe tener sus rutas hijas y anidar
 * solo lo que corresponda
 */
export const privateRoutes = [
  {
    path: "/",
    element: <Home />,

    children: [
      { index: true, element: <General />, menuTitle: "Dashboard" },
      {
        path: "metricas",
        element: <Dashboard />,
        menuTitle: "Tapas",
      },

      {
        path: "transacciones",
        element: <BottleCaps />,
        menuTitle: "Tapas",
      },

      { path: "*", element: <General /> },
    ],
  },
];

export const adminRoutes = [
  {
    path: "/",
    element: <Home />,

    children: [
      {
        path: "beneficios",
        element: <Benefits />,
        menuTitle: "Beneficios",
      },
    ],
  },
];
