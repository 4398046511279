import * as React from "react";
import { TableGeneric } from "../shared/TableGeneric";

export const GeneralResume = ({ data }) => {
  const model = [
    { id: "unidad_operativa", label: "UUOO" },
    {
      id: "beneficios_otorgados",
      label: "Beneficios Otorgados (Tapas)",
      align: "right",
    },
    { id: "tapas_entregadas", label: "Tapas Entregadas", align: "right" },
    { id: "pendiente_entrega", label: "Pendientes de Entrega", align: "right" },
    {
      id: "avance_entrega_porcentual",
      label: "Avance Entrega",
      align: "right",
    },
    {
      id: "beneficios_otorgados_en_botellas",
      label: "Tapas Descuento",
      align: "right",
    },
    {
      id: "objetivo_promo_unidades",
      label: "Tapas Objetivo",
      align: "right",
    },
    {
      id: "avance_promo_porcentual",
      label: "Avance vs Obj",
      align: "right",
    },
  ];

  return <TableGeneric model={model} data={data} total={true} />;
};
