import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { getAllMetrics } from "../../utils/request/rest";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Loading } from "../../components/shared/Loading";

const colors = [
  "#ab385e",
  "#6e63ad",
  "#7fa382",
  "#e0a86c",
  "#abd998",
  "#e0d609",
];

export const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [grpByDay, setGrpByDay] = useState({});
  const [grpByUo, setGrpByUo] = useState({});
  const [grpByController, setGrpByController] = useState({});
  const [grpByTop5, setGrpBy] = useState({});
  const [grpEstado, setGrpEstado] = useState([]);
  const [columnsGrpEstado, setColumnsGrpEstado] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      getMetrics();
    }
    return () => (excecute = false);
  }, []);

  const getMetrics = async () => {
    await getAllMetrics()
      .then((res) => {
        const { by_day, by_uo, by_controller, by_top5, by_estdao_gral } = res;
        setGrpByDay(by_day);
        setGrpByController(by_controller);
        setGrpByUo(by_uo);
        setGrpBy(by_top5);
        setGrpEstado(by_estdao_gral.res);
        setColumnsGrpEstado(Object.keys(by_estdao_gral.res[0]));
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {[grpByTop5, grpByDay, grpByController, grpByUo].map((el, index) => (
          <Grid item xs={12} md={el.size}>
            <ChartCustom item={el} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const ChartCustom = ({ item }) => {
  const {
    title = "Métricas",
    subTitle = "Movimientos principales",
    type = "area",
  } = item;

  let seriesItem = [];
  let xaxisItem = [];
  let metrics = {};

  // default area chart
  if (type === "area") {
    item?.res?.forEach((el) => seriesItem.push(Object.entries(el)[1][1]));
    item?.res?.forEach((el) =>
      xaxisItem.push(moment(Object.entries(el)[0][1]).format("DD/MM/YYYY"))
    );

    metrics = {
      options: {
        markers: { colors: colors },
        fill: { colors: colors },
        colors: colors,
        chart: {
          height: 500,
          type: type,
          zoom: { enabled: false },
        },
        stroke: { curve: "straight" },
        title: { text: title, align: "left" },
        subtitle: { text: subTitle, align: "left" },
        dataLabels: { enabled: false },
        yaxis: { opposite: true },
        xaxis: { categories: xaxisItem },
      },
      series: [{ name: title, data: seriesItem }],
    };
    return (
      <Chart options={metrics.options} series={metrics.series} type={type} />
    );
  } else if (type === "pie") {
    if (Object.keys(item.res[0]).length > 2) {
      item?.res?.forEach((el) =>
        seriesItem.push(parseInt(Object.entries(el)[2][1]))
      );
      item?.res?.forEach((el) => xaxisItem.push(Object.entries(el)[1][1]));
    } else {
      item?.res?.forEach((el) =>
        seriesItem.push(parseInt(Object.entries(el)[1][1]))
      );
      item?.res?.forEach((el) => xaxisItem.push(Object.entries(el)[0][1]));
    }

    metrics = {
      series: seriesItem,
      options: {
        height: 500,
        markers: { colors: colors },
        fill: { colors: colors },
        colors: colors,
        chart: { type: type, height: 500, zoom: { enabled: false } },
        title: { text: title, align: "left" },
        subtitle: { text: subTitle, align: "left" },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 420,
            options: {
              chart: {
                width: 320,
                height: 500,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: xaxisItem,
        dataLabels: {
          dropShadow: {
            blur: 3,
            opacity: 0.8,
          },
        },
      },
    };
    return (
      <Chart options={metrics.options} series={metrics.series} type={type} />
    );
  } else {
    item?.res?.forEach((el) =>
      seriesItem.push(parseInt(Object.entries(el)[2][1]))
    );
    item?.res?.forEach((el) => {
      xaxisItem.push([
        Object.entries(el)[0][1].toString(),
        Object.entries(el)[1][1].toString(),
      ]);
    });

    metrics = {
      series: [
        {
          data: seriesItem,
        },
      ],
      options: {
        height: 500,
        title: { text: title, align: "left" },
        subtitle: { text: subTitle, align: "left" },
        markers: { colors: colors },
        fill: { colors: colors },
        colors: colors,
        chart: {
          height: 500,
          type: "bar",
        },
        plotOptions: {
          options: {
            height: 500,
          },
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: xaxisItem,
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
    };

    return (
      <Chart options={metrics.options} series={metrics.series} type={"bar"} />
    );
  }
};
