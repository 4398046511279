import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getTrxsFilters } from "../../../app/utils/request/rest";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Title from "../../../app/components/home/Title";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const columns = [
  { id: "created_at", label: "Fecha", minWidth: 100 },
  { id: "unidad_operativa", label: "Unidad operativa", minWidth: 100 },
  { id: "ruta", label: "Ruta", minWidth: 100 },
  { id: "controller", label: "Controlador", minWidth: 100 },
  { id: "controller_name", label: "Responsable", minWidth: 100 },
  { id: "tapitas", label: "Cant. Tapas", minWidth: 100 },
];

const columnsMobile = [
  { id: "created_at", label: "Fecha", minWidth: 100 },
  { id: "ruta", label: "Ruta", minWidth: 100 },
  { id: "tapitas", label: "Cant. Tapas", minWidth: 100 },
];

export const BottleCaps = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();
  
  const [qtyTapas, setQtyTapas] = useState(0);

  const [rows, setRows] = useState([]);
  // si selecciona distribuidora es el nombre del distribuidor
  const [rutaCustom, setRutaCustom] = useState();

  const [filteredData, setFilteredData] = useState([]);
  const [objFilter, setObjFilter] = useState({});

  // si selecicona distribuidora como UOpe entonces muestra predeterminadas rutas segun distribuidro seleccionado
  const unOperativas = [
    { id: 1, optionVal: "DISTRIBUIDORA" },
    { id: 2, optionVal: "CENTRO" },
    { id: 3, optionVal: "NORTE" },
    { id: 4, optionVal: "OESTE" },
    { id: 5, optionVal: "SUR" },
    { id: 6, optionVal: "YPF"},
    { id: 7, optionVal: "SHELL"},
    { id: 8, optionVal: "AXION"},
    { id: 8, optionVal: "OTROS"}

  ];
  const [selectedUnidadOreativa, setSelectedUnidadOreativa] = useState("");

  const rutasByDistribuidora = [
    "SELECCIONAR...",
    "FERRARO",
    "CEGEK",
    "YAMANIL",
    "SALEMA",
    "GULY",
    "NORIA",
    "REBESA",
    "RUTA 5",
  ];
  const [selectedDistribuidor, setSelectedDistribuidor] = useState();
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const movements = [
    { id: 1, optionVal: "ENTRADA" },
    { id: 2, optionVal: "SALIDA" },
  ];
  const [selectedMovement, setSelectedMovement] = useState("");
  const [value, setValue] = useState(new Date("2014-08-18T21:11:54"));

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      if (selectedUnidadOreativa === "DISTRIBUIDORA") {
        setRutaCustom(selectedDistribuidor);
      } else {
        setRutaCustom("");
      }
    }
    return () => (excecute = false);
  }, [selectedUnidadOreativa, selectedDistribuidor]);

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      getTrxsFiltersNow({});
    }
    return () => (excecute = false);
  }, []);

  const getTrxsFiltersNow = async (obj) => {
    const res = await getTrxsFilters(obj);
    let totalTapas = 0;
    await setFilteredData(res);
    res.map((el) => (totalTapas = totalTapas + el.tapitas));
    setQtyTapas(totalTapas);
  };

  const searchFiltered = async () => {
    await setObjFilter({});

    if (selectedUnidadOreativa?.length > 0) {
      objFilter.unidad_operativa = selectedUnidadOreativa;
    }

    if (rutaCustom?.length > 0) {
      objFilter.ruta = rutaCustom;
    }

    if (selectedFrom) {
      let aux = moment(selectedFrom).format("YYYY-MM-DD");
      objFilter.from = aux;
    }

    if (selectedTo) {
      let aux = moment(selectedTo).format("YYYY-MM-DD");
      objFilter.to = aux;
    }

    if (selectedMovement?.length > 0) {
      objFilter.tipo = selectedMovement;
    }

    getTrxsFiltersNow(objFilter);
  };

  const resetValues = async () => {
    await setObjFilter({});
    await setSelectedUnidadOreativa("");
    await setSelectedMovement("");
    await setRutaCustom("");
    await setSelectedDistribuidor("");
    await setSelectedFrom(null);
    await setSelectedTo(null);
  };

  const cleanFilters = async () => {
    await resetValues();
    await getTrxsFiltersNow({});
  };


  function handleSelected(event, setValue) {
    let aux = event.target.value.toUpperCase();
    setValue(aux);
  }

  const handleFromChange = (newValue) => {
    setSelectedFrom(newValue);
  };
  const handleToChange = (newValue) => {
    setSelectedTo(newValue);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <>
            <FormControl sx={{ m: 1, minWidth: 300 }} size="medium">
              <InputLabel id="unidad-operativa-select-label">
                Unidad Operativa
              </InputLabel>
              <Select
                variant="standard"
                name="selectedUnidadOreativa"
                labelId="unidad-operativa-select-label"
                id="unidad-operativa-select"
                label="Unidad Operativa"
                value={selectedUnidadOreativa}
                onChange={(e) => handleSelected(e, setSelectedUnidadOreativa)}
              >
                {unOperativas.map((item, index) => (
                  <MenuItem value={item.optionVal} key={item.id}>
                    {item.optionVal}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedUnidadOreativa === "DISTRIBUIDORA" && (
              <FormControl sx={{ m: 1, minWidth: 300 }} size="medium">
                <InputLabel id="distribuidor-select-label">
                  Distribuidor
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="distribuidor-select-label"
                  id="distribuidor-select"
                  label="Distribuidor"
                  value={selectedDistribuidor}
                  onChange={(e) => handleSelected(e, setSelectedDistribuidor)}
                >
                  {rutasByDistribuidora.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedUnidadOreativa !== "DISTRIBUIDORA" && (
              <TextField
                sx={{ m: 1, minWidth: 300 }}
                label="Ruta"
                placeholder="Ingresar ruta..."
                value={rutaCustom}
                onChange={(e) => {
                  console.log(e);
                  handleSelected(e, setRutaCustom);
                }}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack
                sx={{                                    
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <DesktopDatePicker
                  label="Desde"
                  inputFormat="DD/MM/yyyy"
                  value={selectedFrom}
                  onChange={handleFromChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  label="Hasta"
                  inputFormat="DD/MM/yyyy"
                  value={selectedTo}
                  onChange={handleToChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </>
        </Paper>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",

            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ m: 1, minWidth: 300 }}
            size="medium"
            color="error"
            variant="outlined"
            onClick={searchFiltered}
          >
            Filtrar
          </Button>

          {(rutaCustom ||
            selectedDistribuidor ||
            selectedUnidadOreativa ||
            selectedFrom ||
            selectedTo) && (
            <Button
              sx={{ m: 1, minWidth: 300 }}
              size="medium"
              color="primary"
              variant="outlined"
              onClick={cleanFilters}
            >
              Limpiar
            </Button>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 150,
          }}
        >
          <>
            <Title>Total Tapitas</Title>
            <Typography component="p" variant="h4">
              {qtyTapas}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              (*)Según filtros aplicados
            </Typography>
          </>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ display: "flex", flexDirection: "column" }}>
          {filteredData?.length === 0 ? (
            <Paper
              sx={{
                display: "flex",
                height: 450,
                width: "100%",
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography color="textSecondary" variant="h5">
                No hay transacciones para la búsqueda realizada.
              </Typography>
            </Paper>
          ) : (
            <Paper>
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {(isTabletOrMobile ? columnsMobile : columns).map(
                        (column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredData                      
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {(isTabletOrMobile ? columnsMobile : columns).map(
                              (column) => {
                                const value =
                                  column.id === "created_at"
                                    ? moment(row[column.id]).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

             
            </Paper>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
