import axios from "axios";

// axios.defaults.baseURL = "https://femsa.weareblive.io";

axios.defaults.baseURL = "https://api.femsa.ar"

// axios.defaults.baseURL = "http://localhost:9999";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "Bearer SoRtEoS@2022";

/**
 * Es la peticion sin parametros a realizar
 * @param {*} endpoint
 * @returns
 */
async function getWithoutParams(endpoint) {
  try {
    const response = await axios.get(endpoint);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
    }
  } catch (error) {
    return error;
  }
}

/**
 *
 * @param {*} endpoint
 * @param {*} body
 * @returns
 */
async function post(endpoint, body, headers) {
  try {
    const response = await axios.post(endpoint, body, {
      data: "",
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      console.error("Error 404");
      return null;
    } else {
      console.error("Error en la consulta");
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}

export { getWithoutParams, post };
