import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

export const TableGeneric = ({ model = [], data = [], total = false }) => {
  var numberFormat = new Intl.NumberFormat("es-ES");
  const [dataTotal, setDataTotal] = useState([]);

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      sumTotals();
    }
    return () => (excecute = false);
  }, [data]);

  const sumTotals = () => {
    let acumBeneficios = 0;
    let acumTapasRecibidas = 0;
    let acumSaldo = 0;
    let acumAvance = 0;
    let acumBeneficiosBot = 0;
    let acumObjetiveUn = 0;
    let acumAvancePromo = 0;

    data.forEach((elem) => {
      let benef = parseInt(elem["beneficios_otorgados"]) | 0;
      acumBeneficios = acumBeneficios + benef;
      
      acumTapasRecibidas =
        acumTapasRecibidas +
        ((parseInt(elem["tapas_entregadas"] || elem["tapas_recibidas"])) | 0);
      acumSaldo =
        acumSaldo + parseInt(elem["pendiente_entrega"] || elem["saldo"]);
      acumAvance = acumAvance + parseInt(elem["avance_entrega_porcentual"]);
      acumBeneficiosBot =
        acumBeneficiosBot + (parseInt(elem["beneficios_otorgados_en_botellas"]) | 0);
      acumObjetiveUn =
        acumObjetiveUn + parseInt(elem["objetivo_promo_unidades"]);
      acumAvancePromo = acumAvancePromo + parseInt(elem["avance_promo_porcentual"]);

    });
    setDataTotal([
      {
        unidad_operativa: "TOTALES",
        beneficios_otorgados: acumBeneficios,
        tapas_entregadas: acumTapasRecibidas,
        tapas_recibidas: acumTapasRecibidas,
        saldo: acumSaldo,
        pendiente_entrega: acumSaldo,
        avance_entrega_porcentual: Math.round((acumTapasRecibidas / acumBeneficios)*100),
        beneficios_otorgados_en_botellas: acumBeneficiosBot,
        objetivo_promo_unidades: acumObjetiveUn,
        avance_promo_porcentual: Math.round((acumBeneficiosBot / acumObjetiveUn)*100),
      },
    ]);
  };

  const downloadExcel = () => {
    console.log("Data: ", data);
    console.log("Data: ", dataTotal);
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte_Promo_Tapas");
      // //buffer
      let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      // //BINARY
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      // //Download
      XLSX.writeFile(workbook, "Reporte_Promo_Tapas_data.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        style={{ backgroundColor: "black", color: "white" }}
        onClick={() => downloadExcel()}
      >
        Descargar Excel
      </Button>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {model.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {model.map((column) => {
                    let value = "";

                    if (column.id === "avance_entrega_porcentual") {
                      value = `${row["avance_entrega_porcentual"]} %`;
                    } else if (column.id === "saldo_percent") {
                      value =
                        (row["tapas_recibidas"] * 100) /
                        row["beneficios_otorgados"];
                      value = `${Math.abs(parseInt(value))}%`;
                    } else if (column.id === "beneficios_otorgados_botellas") {
                      value = row["beneficios_otorgados"] / 5;
                    } else if (column.id === "pendiente_entrega") {
                      value = row["pendiente_entrega"];
                    } else if (column.id === "avance_promo_porcentual") {
                      value = `${row["avance_promo_porcentual"]} %`;
                    } else {
                      value = isNaN(row[column.id])
                        ? row[column.id]
                        : new Intl.NumberFormat("de-DE").format(
                            Math.abs(row[column.id])
                          );
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            {total &&
              dataTotal.map((row) => {
                return (
                  <TableRow
                    style={{
                      backgroundColor: "black",
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {model.map((column) => {
                      let value = "";

                      if (column.id === "avance_entrega_porcentual") {
                        value = `${row["avance_entrega_porcentual"]} %`;
                      } else if (column.id === "avance_promo_porcentual") {
                        value = `${row["avance_promo_porcentual"]} %`;
                      } else {
                        value = isNaN(row[column.id])
                          ? row[column.id]
                          : new Intl.NumberFormat("de-DE").format(
                              Math.abs(row[column.id])
                            );
                      }
                      return (
                        <TableCell
                          style={{
                            color: "white",
                            fontWeight: "bold",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
