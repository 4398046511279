import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SpeedIcon from '@mui/icons-material/Speed';


export const mainListItems = [
  {
    path: "/",
    text: "General",
    icon: () => <SpeedIcon />,
  },
  {
    path: "metricas",
    text: "Métricas",
    icon: () => <AutoGraphIcon />,
  },
  {
    path: "transacciones",
    text: "Transacciones",
    icon: () => <FormatListBulletedIcon />,
  },
];


export const adminListItems = [ 
  {
    path: "beneficios",
    text: "Carga de archivos",
    icon: () => <FormatListBulletedIcon />,
  },
];
