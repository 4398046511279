/**
 * Este js se encarga de la gestión del estado propio de las vistas signin y signup
 **/
import { createSlice } from "@reduxjs/toolkit";

/**
 * CreateSlice es un metodo que recibe un objeto de tres atributos
 * name - es el nombre de la objeto del store
 * initialState - es el estado con el que se inicia el objeto al levantar la applicacion
 * reducers - combina el action y el cambio de state de ese objeto cuando se usa
 */
export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: null,
    authenticated: false,
    fullName: "",
    userLogin: "",
    roleAdmin: false,
  },
  reducers: {
    login: (state, action) => {
      state.authenticated = action.payload.authenticated;
      state.token = action.payload.token;
      state.fullName = action.payload.fullName;
      state.userLogin = action.payload.userLogin;
      state.roleAdmin = action.payload.roleAdmin;
    },
    logout: (state) => {
      state.token = null;
      state.authenticated = false;
      state.fullName = "";
      state.roleAdmin = false;
    },
    rememberUser: (state, action) => {
      state.userLogin = action.payload.userLogin;
    },
  },
});

/**
 * Por un lado se exportan los actions asociados al reducer
 * por el otro se exporta el reducer en si mismo
 */
export const { login, logout, rememberUser } = sessionSlice.actions;
export default sessionSlice.reducer;
