import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./views/session/sessionSlice";

/**
 * Se crea el store para exportar los reducer a la apliocacion
 */
export default configureStore({
  reducer: {
    session: sessionReducer,
  },
});
