import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getGeneralResume } from "../../utils/request/rest";
import { DetailResume, GeneralResume } from "../../components/home";
import { usePickerState } from "@mui/x-date-pickers/internals/hooks/usePickerState";
import { Loading } from "../../components/shared/Loading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const General = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState([]);
  const [detail, setDetail] = useState([]);
  const [update, setUpdate] = useState();

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      getAllGeneralResume();
    }
    return () => (excecute = false);
  }, []);

  const getAllGeneralResume = async () => {
    await getGeneralResume()
      .then((res) => {
        setSummary(res.summary);
        setDetail(res.detail);
        setUpdate(res.updated_at);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return <Loading />
  }

  return (
    <Box>
      <Box>
        {update && (
          <Box style={{ textAlign: "end", marginRight: 30 }}>
            <i>
              Última actualización de {update.processed} registros el{" "}
              {update.created_at}{" "}
              por {update.usuario.toUpperCase()}
            </i>
          </Box>
        )}

        <Tabs
        indicatorColor="secondary"
        textColor="secondary"
          value={value}
          onChange={handleChange}
          aria-label="table coca"
        >
          <Tab label="RESUMEN" {...a11yProps(0)} />
          <Tab label="DETALLE" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <GeneralResume data={summary} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DetailResume data={detail} />
        </TabPanel>
      </Box>
    </Box>
  );
};
