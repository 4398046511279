import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imgBG from "../../../assets/FEMSA.jpeg";

/**
 * Para implementar Firebase hay que instalarlo e importarlo
 */
// import { appFB } from "../../utils/firebase/config";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { company } from "../../utils/data_config";
import { login, rememberUser } from "./sessionSlice";
import { signInLogin } from "../../utils/request/rest";

export default function SignIn() {
  const dispatch = useDispatch();
  const { userLogin } = useSelector((state) => state.session);
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let res = {};
    const data = new FormData(event.currentTarget);
    const user = data.get("email");
    const pass = data.get("password");

    if (user && pass) {
      userSignIn(user.toLowerCase(), pass);

      if (data.get("remember")?.length > 0) {
        dispatch(rememberUser({ userLogin: user }));
      } else {
        dispatch(rememberUser({ userLogin: "" }));
      }
    }
  };

  const userSignIn = async (user, pass) => {
    const { success, res } = await signInLogin({ user: user, pwd: pass });
    let root = res.profile === "ROOT" ? true : false;

    if (success) {
      dispatch(
        login({
          token: res.id,
          authenticated: true,
          fullName: res.profile,
          userLogin: res.profile.toLowerCase(),
          roleAdmin: root,
        })
      );
      setInvalid(false);
    } else {
      setInvalid(true);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${imgBG})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          {invalid && (
            <Typography component="p" color={"red"}>
              (*) Los datos ingresados son incorrectos
            </Typography>
          )}

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Usuario"
              name="email"
              autoComplete="email"
              autoFocus
              value={userLogin ? userLogin.toLowerCase() : null}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={
                <Checkbox name="remember" value="remember" color="primary" />
              }
              label="Recordar mi usuario"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderBlockColor: "red" }}
              color="error"
            >
              Iniciar Sesión
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                {/* <Link href="sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        {company}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
