import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { BENEFICIOS } from "../../utils/request/enpoints";
import axios from "axios";

const Input = styled("input")({
  display: "none",
});

export const Benefits = () => {
  const { token } = useSelector((state) => state.session);
  const [auxObjBenefit, setAuxObjBenefit] = useState(null);
  const [resultOp, setResultOp] = useState(
    "Seleccione el archivo que desea subir"
  );

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      if (auxObjBenefit) {
        uploadBenefits();
      }
    }
    return () => (excecute = false);
  }, [auxObjBenefit]);

  const uploadBenefits = async () => {
    try {
      const response = await axios.post(BENEFICIOS, auxObjBenefit, {
        data: "",
        headers: {
          Authorization: "Bearer BlIeVe@2022",
        },
      });

      if (response.status === 200) {
        setResultOp("Archivo subido correctamente!");
        return response.data;
      } else if (response.status === 400) {
        setResultOp("Hubo un error de carga. Re intente en unos minutos.");
        return null;
      } else {
        console.error("Error en la consulta");
        return null;
      }
    } catch (error) {
      setResultOp("Hubo un error de carga. Re intente en unos minutos.");
      console.error(error);
    }
  };

  const uploadExcel = (e) => {
    let file = null;
    let promises = [];
    let indexRuta = 0;
    let indexDistribuidor = 0;
    let i = 0;
    e.preventDefault();
    try {
      let files = e.target.files;
      let f = files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });

        readedData.SheetNames.forEach((sheet) => {
          console.log("sheet: ", sheet.toLowerCase().trim());
          if (sheet.toLowerCase().trim() === "indirecta") indexDistribuidor = i;
          if (sheet.toLowerCase().trim() === "directa") indexRuta = i;
          i++;
        });

        /* Convert array to json*/
        //const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const hojaDistribuidores = XLSX.utils.sheet_to_json(
          readedData.Sheets[readedData.SheetNames[indexDistribuidor]]
        );
        const hojaRutas = XLSX.utils.sheet_to_json(
          readedData.Sheets[readedData.SheetNames[indexRuta]]
        );

        ///////////////Semiprocesamiento de archivos para reducir el tamaño de los parametros a pasar
        let distribuidores = {}; 
        let directos = {}; 
        let listaDirectos = [];
        let listaIndirectos = [];
        

        
        hojaRutas.forEach((res) => {

          let ruta = res['Ruta de Venta'];
          const uo = res['Zona de Ventas'];
          const beneficio = res['CJ Venta\r\nAño Actual'];
          if(uo === 'SHELL')
            ruta = 'SHELL';
          if(uo === 'OPESSA')
            ruta = 'YPF';
          if(uo === 'AXION')
            ruta = 'AXION';
          if(uo === 'OTROS')
            ruta = 'OTROS';

          if(!directos[ruta]) directos[ruta] = [];
          directos[ruta].push(beneficio);
          
        });
        console.log(directos);


        let keys = '';
        
        Object.keys(directos).forEach(key => {
         
          let sum =  directos[key].reduce(function(a, b){
            return a + b;
          }, 0);
          
          listaDirectos.push({ruta: key, beneficios: sum});

          keys += key + ', ';
         
        })

        console.log(keys);

        console.log(listaDirectos);
        
        hojaDistribuidores.forEach((dist) => {
          let nombreDist = dist['Distribuidor'];

          if(nombreDist ==='RUTA5')
            nombreDist = 'RUTA 5';

          if(nombreDist === 'SALESWAY')
            nombreDist = 'REBESA';

          if(!distribuidores[nombreDist]) distribuidores[nombreDist] = [];
          distribuidores[nombreDist].push(dist['Cantidad']);
        })

        console.log(distribuidores);
        
        Object.keys(distribuidores).forEach(key => {
          
          let sum =  distribuidores[key].reduce(function(a, b){
            return a + b;
          }, 0);
          
          // distribuidores[key].forEach(value => beneficios += parseInt(value));

          if(key === 'RUTA5')
            key = 'RUTA 5';

          if(key === 'SALESWAY')
            key = 'REBESA';
          
          listaIndirectos.push({ruta: key, beneficios: Math.round(sum)});
         
        })

        console.log('Indirectos');
        console.log(listaIndirectos);
        
        console.log(listaDirectos);
        console.log(listaIndirectos);

        ///////////////////

        
        setAuxObjBenefit({
          usuario: token,
          hojaRutas: listaDirectos,
          hojaDistribuidores: listaIndirectos,
        });
      };
      reader.readAsBinaryString(f);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        
      }}
    >
      <strong>{resultOp}</strong>
      <label htmlFor="contained-button-file">
        <Input
          id="contained-button-file"
          type="file"
          onChange={(event) => uploadExcel(event)}
        />
        <Button color="error" variant="contained" component="span">
          SUBIR ARCHIVO DE BENEFICIOS
        </Button>
      </label>
    </div>
  );
};
